<template>
    <v-layout row justify-center>
        <v-snackbar v-model="showAlert" :timeout="4000" :top="true" color="error">
            {{ errorMessage }}
        </v-snackbar>

        <v-dialog v-model="showSignUp" persistent max-width="600px">
            <!--<btn slot="activator" color="primary" dark small>Open Dialog</btn>-->
            <v-card>
                <v-card-title> <span class="headline">Login</span> </v-card-title>
                <v-form>
                    <v-card-text>
                        <v-container grid-list-md>
                            <v-layout v-if="showTwoFaPanel" wrap>
                                2fa code has been emailed to you.
                                <v-otp-input v-model="twoFaCode" :length="twoFaLength" dark></v-otp-input>
                            </v-layout>
                            <v-layout v-else wrap>
                                <v-flex xs12>
                                    <text-field
                                        label="Organisation*"
                                        hint=""
                                        persistent-hint
                                        v-model="org"
                                        required
                                        autofocus
                                    ></text-field>
                                </v-flex>
                                <v-flex xs12>
                                    <text-field
                                        label="Email*"
                                        persistent-hint
                                        v-model="email"
                                        required
                                    ></text-field>
                                </v-flex>
                                <v-flex xs12>
                                    <text-field
                                        v-model="password"
                                        label="Password*"
                                        type="password"
                                        required
                                    ></text-field>
                                </v-flex>
                            </v-layout>
                        </v-container>
                        <small>*indicates required field</small>
                    </v-card-text>
                    <v-card-actions v-if="showTwoFaPanel">
                        <btn color="blue darken-1" text small @click="doLogin">Login</btn>
                    </v-card-actions>
                    <v-card-actions v-else>
                        <btn color="blue darken-1" text small @click="gotoSignUp">Go To Sign Up</btn>
                        <v-spacer></v-spacer>
                        <btn color="blue darken-1" text small @click="gotoTwoFa">Login</btn>
                    </v-card-actions>
                </v-form>
            </v-card>
        </v-dialog>
    </v-layout>
</template>

<script>
import { mapActions } from 'vuex';
import api from '@/api';

/* eslint-disable */
export default {
    data() {
        return {
            showSignUp: true,
            org: '',
            email: '',
            password: '',
            showAlert: false,
            errorMessage: '',
            showTwoFaPanel: false,
            twoFaCode: '',
            twoFaLength: 6,
        };
    },
    methods: {
        ...mapActions(['loginUser']),
        gotoSignUp() {
            this.$router.push('/signup');
        },
        async getMyIp() {
            try {
                const ipString = await fetch('https://api.ipify.org?format=json');
                const { ip } = await ipString.json();
                return ip;
            } catch (e) {
                return null;
            }
        },

        async gotoTwoFa() {
            this.twoFaCode = '';
            const ip =  this.getMyIp();

            if (ip) {
                const result = await api.post(this.$store, 'auth/starttwofa', {
                    org: this.org,
                    email: this.email,
                    ip,
                });
                if (result.data) {
                    console.log(result.data);
                    if (result.data.message === '2fa already completed') {
                        await this.doLogin();
                    } else {
                        this.showTwoFaPanel = true;
                    }
                } else {
                    this.errorMessage = 'Start 2fa session failed';
                    this.showAlert = true;
                }
            } else {
                this.errorMessage = 'System error in login.';
                this.showAlert = true;
            }
        },
        async doLogin() {
            const { redirectTo } = this.$route.query;
            try {
                const ip = this.getMyIp();

                if (ip) {
                    this.$cookies.set('userEmail', this.email);
                    this.$cookies.set('userOrg', this.org);
                    const result = await this.loginUser({
                        org: this.org,
                        email: this.email,
                        password: this.password,
                        twoFaCode: this.twoFaCode,
                        ip,
                    });
                    const { sid } = result;
                    this.$emit('change', sid);
                    this.showTwoFaPanel = false;
                    await this.$router.push(redirectTo || '/auth/dashboard');
                } else {
                    this.errorMessage = 'System error in login.';
                    this.showAlert = true;
                }
            } catch (e) {
                this.showTwoFaPanel = false;

                this.errorMessage = 'Incorrect Login 😥';
                this.showAlert = true;
            }
        },
    },
    mounted() {
        // console.log(this.$cookies.get('userEmail'), this.$cookies.get('userOrg'));
        this.email = this.$cookies.get('userEmail') || '';
        this.org = this.$cookies.get('userOrg') || '';
    },
    name: 'App',
};
</script>
